import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ComboBox, ComboBoxItem } from '@ui5/webcomponents-react';
import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import axios from 'axios';

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
}

const pathname = new URL(window.location).pathname;
const [env, tenantId] = pathname.slice(1).split('/');
const [namespace, cluster] = env.split('.');

const pathnameValid = !!(cluster && namespace && tenantId);

if (pathnameValid) {
  axios
    .get(`/api/mockserver/business/v1/mock-server/${cluster}/${namespace}/${tenantId}`)
    .then(({ data: { configUrl } }) => {
      if (configUrl) {
        return axios.get(`${configUrl}/config.json`);
      } else {
        return Promise.reject();
      }
    })
    .then(
      ({ data: config }) => {
        const cdnPrefix = config['shell-ui'].location;
        window.uiRouting5ResourceBasePath = cdnPrefix + '/';
        window.hashCommit = cdnPrefix.slice(-8);
        axios.get(`${cdnPrefix}/asset-manifest.json`).then(({ data: resourceMap }) => {
          const mainJsSrc = resourceMap.files['main.js'].replace(/\{\{\.CdnPrefix\}\}\/?/, '');
          const e = document.createElement('script');
          e.setAttribute('src', `${cdnPrefix}/${mainJsSrc}`), document.head.appendChild(e);
        });
      },
      () => {
        document.getElementById('eureka-app').innerHTML =
          '<div class="mockhost-parameter-error">Incorrect cluster, namespace or tenantId.</div>';
      },
    );
}

const CustomerHeader = () => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    axios.get('/api/mockserver/business/v1/mock-server/list').then(({ data }) => {
      setSettings(data || []);
    });
  }, []);

  const handleSettingsChange = (e) => {
    const selectedOption = e.detail.item.dataset;
    window.location.href = `${window.location.origin}/${selectedOption.namespace}.${selectedOption.cluster}/${selectedOption.tenantid}`;
  };

  return (
    <div>
      <span>Customer page</span>
      <ComboBox
        style={{ width: '300px', marginLeft: '20px' }}
        filter="Contains"
        placeholder="Please select environment"
        value={pathnameValid ? pathname : ''}
        onSelectionChange={handleSettingsChange}
      >
        {settings.map((option, i) => (
          <ComboBoxItem
            key={i}
            data-cluster={option.cluster}
            data-namespace={option.namespace}
            data-tenantid={option.tenantId}
            text={`${option.namespace}.${option.cluster}/${option.tenantId}`}
          />
        ))}
      </ComboBox>
    </div>
  );
};

ReactDOM.render(<CustomerHeader />, document.getElementById('customer-header'));
